import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/ja'; // 日语语言包
import 'normalize.css/normalize.css';
import './assets/css/reset.css'
import './assets/css/common.css'
import '@/assets/js/rem'
import "github-markdown-css";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import lottie from 'lottie-web';
Vue.prototype.$lottie = lottie;
import * as echarts from 'echarts'
import 'echarts/i18n/langEN';
Vue.prototype.$echarts = echarts

import SoeEngine from "@/assets/js/engine/soeEngine.min"
Vue.prototype.$SoeEngine = SoeEngine
Vue.prototype.$globalParam = {
  // 预发布环境
  // domain: 'pre.api.smart-speech.com',
  // appId: '85f68b7b-ffd5-45d6-84de-323139a7f251',
  // appSecret : '61bc9abbd2e8fe37',

  // 正式环境
  // domain: 'api.ai.smart-speech.com',
  // appId: '1f47fcff-5557-47b3-8727-c6c7b3625278',
  // appSecret : '66e7fe2eb139a87a',

  // 日本环境
  domain: 'api.soe.dolphin-ai.jp',
  appId: '5a4e241e-d79d-4dc0-9399-53070f0278ad',
  appSecret : '79cc041c7041be43',

  i18n: 'en',

  log: false,  // 是否开启log输出
}

locale.use(lang);
Vue.use(ElementUI);
Vue.use(mavonEditor);

Vue.config.productionTip = false;

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
